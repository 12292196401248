html {
  scroll-behavior: smooth;
}

.js-gallery-image {
  transform-style: preserve-3d;
  transform: perspective(1000px);
}

.js-gallery-image-item{
  transform: translateZ(50px);
}

.js-gallery-image-text {
  transform: translateZ(120px);
}
